import React from "react"
import { Col, Container as BootContainer, Row } from "react-bootstrap"
import styled from "styled-components"

const DropDown = styled.div`
  transition: all 0.2s ease-out;
  max-height: ${props => (props.open ? "500px" : "50px")};
  overflow: hidden;
`
const Container = styled(BootContainer)`
  padding-top: 50px;
  padding-bottom: 50px;
`
const DropDownTitle = styled.h3``

const Arrow = styled.span`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  margin-right: 10px;
`

const TitleRow = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.blackblue};
`

const DropDownBody = styled.div`
  margin-left: 20px;
  color: ${props => props.theme.darkblue};
  margin-top: 20px;
`

const ComeFunziona = {
  title: "Come funziona",
  items: [
    {
      title: "Come posso prenotare?",
      content: "Prenotare è semplice e veloce:",
    },
    {
      title:
        "Posso effettuare il videoconsulto da ogni device e sistema operativo?",
      content:
        "Si, il nostro sitema è utilizzabile da qualsiasi device ( smartphone, pc, tablet) o sistema operativo.",
    },
    {
      title: "Come funziona",
      content:
        "Una volta effettuata la prenotazione e il pagamento, ti verrà inviato tramite sms e tramite email, la conferma e il riepilogo dell'avvenuta prenotazione. 15 minuti prima del videoconsulto, ti verrà inviato un link al quale connetterti per poter parlare con lo specialista",
    },
  ],
}

const DropDownSingle = ({ title, children }) => {
  const [open, setOpen] = React.useState(true)
  return (
    <DropDown open={open}>
      <TitleRow onClick={() => setOpen(!open)}>
        <Arrow></Arrow>
        <DropDownTitle>{title}</DropDownTitle>
      </TitleRow>
      <DropDownBody>{children}</DropDownBody>
    </DropDown>
  )
}

export default function VideoConsultoInnerContainer() {
  return (
    <Container>
      <DropDownSingle title={"Come funziona?"}>
        {ComeFunziona.items.map(item => {
          return (
            <>
              {" "}
              <b>{item.title}</b>
              <br />
              <p style={{ marginTop: 10, marginBottom: 10 }}>{item.content}</p>
            </>
          )
        })}
      </DropDownSingle>
      <div style={{ height: 40 }} />
      <DropDownSingle
        title={"Il videoconsulto può sostituire la visita fisica?"}
      >
        <p>
          no. Questo metodo serve semplicemente ad effettuare una valutazione
          preliminare della tua situazione. In questa fase sarà possibile
          cominciare a parlare delle possibilità che il Dr. Rossi potrà
          offrirti. Prima di prendere qualsiasi tipo di decisione, però, sarà
          necessaria almeno un incontro in persona.
        </p>
      </DropDownSingle>
      <div style={{ height: 40 }} />
      <Row>
        <Col md={6}>
          <DropDownSingle title={"Costi e pagamento"}>
            <p>
              Il videconsulto può essere gratis o pagamento a discrezione dello
              specialista ? Il metodo di pagamento accetta è PayPal, Stripe,
              Carta di credito.
            </p>
          </DropDownSingle>
        </Col>
        <Col md={6}>
          <DropDownSingle title={"Durata"}>
            <p>La durata della video consulenza è di 30 minuti</p>
          </DropDownSingle>
        </Col>
      </Row>
    </Container>
  )
}

import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VideoConsultoHeroContainer from "../components/VideoconsultoHeroContainer/VideoConsultoHeroContainer"
import VideoConsultoInnerContainer from "../components/VideoConsultoInnerContainer/VideoConsultoInnerContainer"
import CardsContainer from "../containers/home/cards"

const VideoConsultoPage = ({ location }) => {
  return (
    <Layout>
      <SEO title="Videoconsulto" />
      <VideoConsultoHeroContainer />
      <VideoConsultoInnerContainer />
      <CardsContainer title={"VIDEOCONSULTO"} />
      <div style={{ height: 150 }} />
    </Layout>
  )
}

export default VideoConsultoPage

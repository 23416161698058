import React, { useRef, useState } from "react"
import HeroSection from "../HeroSection/HeroSection"
import baseImg from "../../images/hero.png"
import { colors } from "../../constants/Colors"
import styled from "styled-components"
import AutoComplete from "../AutoComplete/AutoComplete"
import { Trattamenti } from "../../constants/trattamenti"
import { cities } from "../../constants/cities"
import { Autocomplete } from "@material-ui/lab"
import { Row } from "react-bootstrap"
import { device } from "../../constants/breakpoints"

export const BottomOverlayTitle = styled.h1`
  font-weight: 700;
  font-size: 28px;
  margin-top: 20px;

  @media ${device.tablet} {
    font-size: 18px;
  }
`
const Feature = styled.div`
  text-align: center;
  @media ${device.tablet} {
    margin-bottom: 20px;
    padding-top: 20px;
  }
`

const FeatureTop = styled.div`
  font-size: 28px;

  font-weight: 600;
  color: ${props => props.theme.midblue};
  margin-bottom: 20px;
  text-align: center;

  @media ${device.tablet} {
    padding: 20px;
    font-size: 24px;
    padding-bottom: 0;
    margin-bottom: 0;
    & > span {
      font-size: 20px;
    }
  }
`

const BarItem = styled.div`
  margin: auto;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  padding: 20px;
  color: white;
  @media ${device.tablet} {
    padding: 0;
  }
`

const FeatureTitle = styled.h4`
  color: white;
`

const BarTitle = styled.h6`
  color: white;
`

const Background = styled(Row)`
  min-height: 100px;
  object-fit: cover;
  --bs-gutter-x: 0;
  background-image: url(${props => props.img});
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${({ theme }) => theme.blue};

  @media ${device.tablet} {
    flex-direction: column;
  }
`
const BottomContainer = styled.div`
  text-align: center;
  @media ${device.tablet} {
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
  }
`

export default function VideoConsultoHeroContainer() {
  return (
    <>
      <HeroSection style={{ maxHeight: 400 }} img={baseImg}>
        <FeatureTop>VIDEOCONSULTO CON I MIGLIOR SPECIALISTI</FeatureTop>

        <BottomContainer>
          <BottomOverlayTitle style={{ marginBottom: 20 }}>
            Cerca lo specialista con videoconsulto
          </BottomOverlayTitle>
          <HeroSection.SearchContainer title={"home"}>
            <Autocomplete
              freeSolo
              options={Trattamenti}
              getOptionLabel={option => option}
              renderInput={params => (
                <HeroSection.SearchLeft
                  title={"home"}
                  {...params}
                  label="Trattamento"
                />
              )}
            />
            <Autocomplete
              freeSolo
              options={cities}
              getOptionLabel={option => option}
              renderInput={params => (
                <HeroSection.SearchLeft
                  title={"home"}
                  {...params}
                  label="Località"
                />
              )}
            />
            <HeroSection.SearchButton
              title={"home"}
              img={require("../../images/Search.png")}
            ></HeroSection.SearchButton>
          </HeroSection.SearchContainer>
        </BottomContainer>
      </HeroSection>
      <Background img={require("../../images/background_chi_siamo.png")}>
        <Feature>
          <FeatureTitle>IL VIDEOCONSULTO CON IL TUO ESPERTO</FeatureTitle>
        </Feature>
      </Background>
      <Bar>
        <Feature>
          <BarItem>Consulta il miglior specialista</BarItem>
        </Feature>
        <Feature>
          <BarItem>Servizio sicuro e privato</BarItem>
        </Feature>
        <Feature>
          <BarItem>Facile e comodo</BarItem>
        </Feature>
      </Bar>
    </>
  )
}
